<template>
  <v-container class="container-form">
    <BackArrow class="mt-8" />
    <v-subheader class="title mt-n4 mb-4 pa-0 black--text">
      {{ id ? "Editar Empresa" : "Cadastrar Empresa" }}
    </v-subheader>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      autocomplete="off"
      @submit.prevent
    >
      <v-card class="mb-5">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Informações Principais</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>
        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="6">
              <span class="primary--text">Razão Social *</span>
              <v-text-field
                placeholder="Informe a razão social"
                class="mt-n1"
                v-model="dados.razao_social"
                :rules="fieldRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Nome Fantasia *</span>
              <v-text-field
                placeholder="Informe o nome fantasia"
                class="mt-n1"
                v-model="dados.nome_fantasia"
                :rules="fieldRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">CNPJ *</span>
              <v-text-field
                placeholder="Informe o cnpj"
                class="mt-n1"
                v-model="dados.cnpj"
                :rules="fieldRules"
                v-mask-cnpj
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">Inscrição Estadual *</span>
              <v-text-field
                placeholder="Informe a inscrição estadual"
                class="mt-n1"
                v-model="dados.inscricao_estadual"
                :rules="fieldRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">Inscrição Municipal *</span>
              <v-text-field
                placeholder="Informe a inscrição municipal"
                class="mt-n1"
                v-model="dados.inscricao_municipal"
                :rules="fieldRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Email *</span>
              <v-text-field
                placeholder="Informe o email"
                class="mt-n1"
                v-model="dados.email"
                :rules="emailRules"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <span class="primary--text">Número do SAC</span>
              <v-text-field
                placeholder="Informe o número do sac"
                class="mt-n1"
                v-model="dados.sac"
                v-mask="'9999 999 9999'"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="4">
              <span class="primary--text">CNAE</span>
              <v-text-field
                placeholder="Informe o cnae"
                class="mt-n1"
                v-model="dados.cnae"
                v-mask-number
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">CRT</span>
              <v-text-field
                placeholder="Informe o crt"
                class="mt-n1"
                v-model="dados.crt"
                v-mask-number
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">TAR</span>
              <v-text-field
                placeholder="Informe o tar"
                class="mt-n1"
                v-model="dados.tar"
                v-mask-number
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-5">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Informações de Endereço</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>
        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="4">
              <span class="primary--text">CEP</span>
              <v-text-field
                placeholder="Informe o cep"
                class="mt-n1"
                v-model="dados.cep"
                @blur="getCep(dados.cep)"
                :loading="loading"
                v-mask-cep.br
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Logradouro</span>
              <v-text-field
                placeholder="Informe o logradouro"
                class="mt-n1"
                v-model="dados.logradouro"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <span class="primary--text">Número</span>
              <v-text-field
                placeholder="Informe o número"
                class="mt-n1"
                v-model="dados.numero"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">Bairro</span>
              <v-text-field
                placeholder="Informe o bairro"
                class="mt-n1"
                v-model="dados.bairro"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">Cidade</span>
              <v-text-field
                placeholder="Informe a cidade"
                class="mt-n1"
                v-model="dados.cidade"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <span class="primary--text">Cód. IBGE</span>
              <v-text-field
                placeholder="Informe o cód. ibge"
                class="mt-n1"
                v-model="dados.cibge"
                v-mask-number
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <span class="primary--text">UF</span>
              <v-text-field
                placeholder="UF"
                class="mt-n1"
                v-model="dados.uf"
                v-mask="'UU'"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-15">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Informações de Bp-e</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>
        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="2">
              <span class="primary--text">Cód. IBGE UF</span>
              <v-text-field
                placeholder="Informe o cód. igbe uf"
                class="mt-n1"
                v-model="dados.cod_ibge_uf"
                v-mask-number
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="10">
              <span class="primary--text">Link do Bpe Sefaz</span>
              <v-text-field
                placeholder="Informe o link do bpe sefaz"
                class="mt-n1"
                v-model="dados.link_sefaz"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-footer color="primary" class="rounded-t footer-form" absolute>
        <div class="flex-grow-1"></div>
        <v-btn depressed dark text :disabled="!valid" @click="validate"
          >Confirmar</v-btn
        >
      </v-footer>
    </v-form>

    <v-snackbar
      v-model="message.snacshow"
      :color="message.type"
      :multi-line="message.mode === 'multi-line'"
      :timeout="message.timeout"
      :vertical="message.mode === 'vertical'"
      bottom
    >
      {{ message.text }}
      <v-btn dark text @click="message.snacshow = false">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import BackArrow from "../../../components/BackArrow/index.vue";
export default {
  props: ["id"],
  data() {
    return {
      loading: false,
      message: {
        snacshow: false,
        text: "",
        type: "",
        mode: "",
        timeout: 2000,
      },
      dados: {
        razao_social: "",
        nome_fantasia: "",
        cnpj: "",
        inscricao_estadual: "",
        inscricao_municipal: "",
        logradouro: "",
        bairro: "",
        cep: "",
        telefone: "",
        cidade: "",
        uf: "",
        email: "",
        cnae: 0,
        crt: 0,
        tar: 0,
        cibge: 0,
        cod_ibge_uf: 0,
        link_sefaz: "",
      },
      valid: true,
      fieldRules: [(v) => !!v || "Este campo é requerido"],
      emailRules: [
        (v) => !!v || "E-mail é requerido",
        (v) => /.+@.+\..+/.test(v) || "Digite um e-mail válido!",
      ],
    };
  },
  created() {
    if (this.id) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/empresas/${this.id}`)
        .then((r) => {
          this.dados = r.data[0];
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text =
          "Atencão um ou mais campos precisam ser preenchidos!";
      }
    },
    salvar() {
      this.dados.razao_social = this.dados.razao_social
        ? this.dados.razao_social.toUpperCase()
        : this.dados.razao_social;
      this.dados.nome_fantasia = this.dados.nome_fantasia
        ? this.dados.nome_fantasia.toUpperCase()
        : this.dados.nome_fantasia;
      this.dados.logradouro = this.dados.logradouro
        ? this.dados.logradouro.toUpperCase()
        : this.dados.logradouro;
      this.dados.bairro = this.dados.bairro
        ? this.dados.bairro.toUpperCase()
        : this.dados.bairro;
      this.dados.cidade = this.dados.cidade
        ? this.dados.cidade.toUpperCase()
        : this.dados.cidade;
      this.$store.dispatch("activeLoader", true);
      if (this.id) {
        this.$http
          .post(`/empresas/update/${this.id}`, this.dados)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              setTimeout(() => {
                this.$router.push("/cadastros/empresas");
              }, 500);
            } else {
              this.message.text = r.data.error;
              this.message.snacshow = true;
              this.message.type = "error";
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      } else {
        this.$http
          .post("/empresas/create", this.dados)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              setTimeout(() => {
                this.$router.push("/cadastros/empresas");
              }, 1500);
            } else {
              this.message.text = r.data.error;
              this.message.snacshow = true;
              this.message.type = "error";
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      }
    },
    getCep(cep) {
      if (cep.length == 10) {
        this.loading = true;
        cep = cep.replace("-", "");
        cep = cep.replace(".", "");
        axios
          .get("https://viacep.com.br/ws/" + cep + "/json/")
          .then((response) => {
            this.dados.logradouro = response.data.logradouro;
            this.dados.bairro = response.data.bairro;
            this.dados.cidade = response.data.localidade;
            this.dados.uf = response.data.uf;
            this.dados.cibge = response.data.ibge;
            this.loading = false;
          })
          .catch((e) => {
            this.message.snacshow = true;
            this.message.type = "warning";
            this.message.text = "Atencão cep não encontrado!" + e;
            this.loading = false;
          });
      }
    },
  },
  components: { BackArrow },
};
</script>

<style src="../style.vue"></style>
